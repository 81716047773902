<template>
  <DefaultTemplate
    v-loading.lock="loadingData"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
  >
    <div class="box-name-page">
      <div>
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          class="set-breadcrumb"
        >
          <el-breadcrumb-item :to="{ path: '/manage-admin/add-admin' }"
            >เพิ่มผู้ใช้งาน
          </el-breadcrumb-item>
          <el-breadcrumb-item>เพิ่มข้อมูลผู้ใช้งาน</el-breadcrumb-item>
        </el-breadcrumb>
        <p class="title mg-t-4">เพิ่มข้อมูลผู้ใช้งาน</p>
      </div>
    </div>
    <!-- <el-form label-position="top" :model="form" class="set-form" ref="form"> -->
    <Step1
      @saveData="formDataOut"
      @statusLoading="statusLoading"
      :formData="form"
    />
    <!-- </el-form> -->
  </DefaultTemplate>
</template>

<script>
import DefaultTemplate from "@/template/DefaultTemplate";
import Step1 from "@/components/admin/addAdmin/Step1";
import { HTTP } from "@/service/axios";
import { mapState } from "vuex";

export default {
  components: {
    DefaultTemplate,
    Step1,
  },
  data() {
    return {
      form: {
        department: [],
        division: [],
        position: [],
        role: "",
      },
      loadingData: true,
    };
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
    }),
  },
  mounted() {
    this.$store.commit("SET_PAGE_ACTIVE", "manage-admin/add-admin");
  },
  methods: {
    async formDataOut(data) {
      let obj = {
        roleId: data.role,
        department: data.department,
        division: data.division,
        position: data.position,
      };
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let res = await HTTP.post(`employee/create-role`, obj)
        .then((res) => {
          if (res.data.success) {
            this.$router.push(`/manage-admin/add-admin`);
            this.$message({
              message: "เพิ่มผู้ใช้งาน สำเร็จ",
              type: "success",
              duration: 4000,
            });
          } else {
            if (res.data.status == 404) {
              this.$message({
                message:
                  "ไม่สามารถเพิ่มผู้ใช้งานได้ เนื่องจากไม่พบผู้ใช้งานในฝ่ายและส่วน",
                type: "error",
                duration: 4000,
              });
            } else if (res.data.status == 403) {
              this.$store.commit("SET_PAGE_ACTIVE", null);
              this.$router.push("/not-allowed");
            }
          }
          return res;
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.status != 502) {
              if (e.response.data.status == 401) {
                this.checkAuth();
              } else if (e.response.data.status == 502) {
                console.log("Bad GetWay");
              } else {
                this.alertCatchError(e);
              }
            }
          }
          return e;
        });
      return res;
    },
    statusLoading(status) {
      if (status == true) {
        this.loadingData = false;
      }
    },
  },
};
</script>
