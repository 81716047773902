<template>
  <div>
    <el-form label-position="top" :model="formData" class="set-form" ref="form">
      <div class="animate__animated animate__fadeIn animate__slow">
        <el-card shadow="never" class="card-custom-all" v-loading="!loading">
          <p class="name-head">เพิ่มฝ่ายและส่วนผู้ใช้งาน</p>
          <el-row :gutter="30" v-if="loading">
            <el-col :span="24" :md="12">
              <el-form-item label="ข้อมูลฝ่าย">
                <el-select
                  id="selectDepartment"
                  multiple
                  filterable
                  default-first-option
                  clearable
                  v-model="formData.department"
                  placeholder="เลือกฝ่าย"
                  popper-class="custom-select"
                  class="w-100"
                  @change="selectDepartment"
                  :loading="loadingDepartment"
                >
                  <template slot="empty" v-if="!loadingDepartment">
                    <p class="el-select-dropdown__empty">ไม่มีข้อมูล</p>
                  </template>
                  <el-option
                    v-for="item in optionsDepartment"
                    :key="item.id"
                    :label="item.depName"
                    :value="item.id"
                  >
                    <div class="is-flex ai-center">
                      <div class="circle-select"></div>
                      {{ item.depName }}
                    </div>
                  </el-option>
                </el-select>
                <!-- <el-select
                  multiple
                  filterable
                  default-first-option
                  clearable
                  v-model="formData.department"
                  placeholder="เลือกข้อมูลฝ่าย"
                  popper-class="custom-select"
                  class="w-100"
                  @change="selectDepartment"
                  @remove-tag="outDivition"
                >
                  <template slot="empty">
                    <p class="el-select-dropdown__empty">ไม่มีข้อมูล</p>
                  </template>
                  <el-option
                    v-for="item in optionsDepartment"
                    :key="item.id"
                    :label="item.depName"
                    :value="item.id"
                  >
                    <div class="is-flex ai-center">
                      <div class="circle-select"></div>
                      {{ item.depName }}
                    </div>
                  </el-option>
                </el-select> -->
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item label="ส่วน">
                <el-select
                  id="selectDivision"
                  multiple
                  filterable
                  default-first-option
                  clearable
                  v-model="formData.division"
                  placeholder="เลือกส่วน"
                  popper-class="custom-select"
                  class="w-100"
                  @change="selectDivision"
                  :disabled="formData.department == '' ? true : false"
                >
                  <template slot="empty">
                    <p class="el-select-dropdown__empty">ไม่มีข้อมูล</p>
                  </template>
                  <el-option-group
                    v-for="group in optionsDivision"
                    :key="group.value"
                    :label="group.label"
                  >
                    <el-option
                      v-for="item in group.option"
                      :key="item.id"
                      :label="item.divName"
                      :value="item.id"
                    >
                      <div class="is-flex ai-center">
                        <div class="circle-select"></div>
                        {{ item.divName }}
                      </div>
                    </el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item label="ตำแหน่ง">
                <el-select
                  id="selectPosition"
                  multiple
                  filterable
                  default-first-option
                  clearable
                  v-model="formData.position"
                  placeholder="เลือกตำแหน่ง"
                  popper-class="custom-select"
                  class="w-100"
                  @change="selectAllPosition"
                  :disabled="formData.division == '' ? true : false"
                >
                  <template slot="empty">
                    <p class="el-select-dropdown__empty">ไม่มีข้อมูล</p>
                  </template>
                  <el-option-group
                    v-for="group in optionsPosition"
                    :key="group.value"
                    :label="group.label"
                  >
                    <el-option
                      v-for="item in group.option"
                      :key="item.id"
                      :label="item.posName"
                      :value="item.id"
                    >
                      <div class="is-flex ai-center">
                        <div class="circle-select"></div>
                        {{ item.posName }}
                      </div>
                    </el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
        <el-card shadow="never" class="card-custom-all mg-t-4">
          <p class="name-head">เพิ่มสิทธิ์ผู้ใช้งาน</p>
          <p class="font-22">ข้อมูลสิทธิ์ผู้ใช้งาน</p>
          <el-row :gutter="30" class="is-flex ai-center">
            <el-col :span="24" :md="12">
              <el-select
                v-model="formData.role"
                placeholder="เลือกสิทธิ์ผู้ใช้งาน"
              >
                <el-option
                  v-for="item in optionsRole"
                  :key="item._id"
                  :label="item.roleName"
                  :value="item._id"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="24" :md="12">
              <span
                class="font-18 color-main cursor-pointer"
                @click="openDialogView"
                >รายละเอียดสิทธิ์ผู้ใช้งาน</span
              >
            </el-col>
          </el-row>
        </el-card>
        <div class="is-flex js-end mg-t-4">
          <el-button
            class="font-16 btn-cancle"
            style="min-width: 100px"
            @click="$router.push('/manage-admin/add-admin')"
          >
            ยกเลิก</el-button
          >
          <el-button
            type="primary"
            class="font-16 submit"
            style="min-width: 100px"
            @click="saveAddAdmin"
            :disabled="checkDisabled()"
            >บันทึก</el-button
          >
        </div>
      </div>
    </el-form>
    <el-dialog
      class="dialog-custom admin"
      title="สิทธิ์การเข้าถึง"
      v-if="dialogView"
      :visible.sync="dialogView"
      width="70%"
      top="10vh"
      center
    >
      <div class="overflow-tab mg-b-4">
        <el-radio-group
          class="radio-tabs"
          v-model="tabRole"
          @change="checkTabRole"
        >
          <el-radio-button
            :label="index + 1"
            v-for="(res, index) in roleDetail"
            :key="index"
          >
            {{ res.roleName }}
          </el-radio-button>
        </el-radio-group>
      </div>

      <ManagePermission
        :dataRes="dataManageAll[0].permission"
        class="table-manage"
        :type="`view`"
      />
      <!-- <TablePermissionView :data="dataManageAll[0].permission" /> -->
    </el-dialog>
  </div>
</template>
<script>
import { HTTP } from "@/service/axios";
import ManagePermission from "@/components/admin/ManagePermission";
import { mapState } from "vuex";
export default {
  props: ["formData"],
  components: {
    ManagePermission,
  },
  data() {
    return {
      tabRole: 1,
      dialogView: false,
      optionsDepartment: [],
      optionsDivision: [],
      optionsPosition: [],
      optionsRole: [],
      roleDetail: [],
      dataManageAll: [],
      loading: false,
      setArrPosition: [],
      loadingDepartment: true,
      constSelect2: [],
      constSelect3: [],
    };
  },
  mounted() {
    this.getDepartment();
    this.getRole();
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
    }),
  },
  methods: {
    getDepartment() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`department`)
        .then((res) => {
          if (res.data.success) {
            this.loadingDepartment = false;
            this.optionsDepartment = res.data.obj;
            this.loading = true;
            this.$emit("statusLoading", true);
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    // outDivition(val) {
    //   let findIndex = this.formData.division.findIndex((div) => div === val);
    //   if (findIndex > -1) {
    //     this.formData.division.splice(findIndex, 1);
    //   }
    // },
    selectDepartment() {
      this.optionsDivision = [
        {
          option: [{ id: 999, divName: "ทั้งหมด" }],
        },
      ];
      this.formData.department.forEach((dep) => {
        const setArr = this.optionsDepartment.filter((f) => f.id == dep);
        const forOption = [];
        setArr.forEach((arr) => {
          if (arr.division.length > 0) {
            arr.division.forEach((div) => {
              forOption.push(div);
            });
          }
        });
        let obj = {
          label: setArr[0].depName,
          option: forOption,
        };
        this.optionsDivision.push(obj);
      });
      this.selectDivision();
      this.selectAllPosition();
      if (this.formData.department == 0) {
        this.formData.division = [];
        this.formData.position = [];
      }
    },
    selectDivision(val) {
      if (typeof val == "undefined") {
        let findDiv999 = this.formData.division.find((e) => e == 999);
        if (typeof findDiv999 != "undefined") {
          let groupDiv = [];
          if (this.formData.department.length > 0) {
            this.optionsDivision.forEach((f) => {
              f.option.forEach((option) => {
                groupDiv.push(option.id);
              });
            });
          }
          this.formData.division = groupDiv;
          this.constSelect2 = JSON.parse(
            JSON.stringify(this.formData.division)
          );
        } else {
          if (this.formData.division.length > 0) {
            let groupDiv = [];
            this.formData.division.forEach((row) => {
              this.optionsDivision.forEach((f) => {
                f.option.forEach((option) => {
                  if (option.id == row) {
                    groupDiv.push(option.id);
                  }
                });
              });
            });
            this.formData.division = groupDiv;
          }
        }
      } else {
        if (val.length == 1) {
          if (val[0] === 999 && val.length < this.constSelect2.length) {
            // มีเลือกทั้งหมดอยู่แล้ว Tik ลูกออก และเลือกทั้งหมดจะถูกเอาออก
            this.formData.division.splice(0, 1);
            this.constSelect2 = JSON.parse(
              JSON.stringify(this.formData.division)
            );
          }
        }
        this.selectAllDiv(val);
      }
      this.optionsPosition = [
        {
          option: [{ id: 999, posName: "ทั้งหมด" }],
        },
      ];
      let setArr = [];
      this.formData.division.forEach((e) => {
        this.optionsDivision.forEach((f) => {
          f.option.forEach((option) => {
            if (option.id == e && option.id != 999) {
              setArr.push(option);
            }
          });
        });
      });
      setArr.forEach((arr) => {
        let forOption = [];
        if (arr.position.length > 0) {
          arr.position.forEach((div) => {
            forOption.push(div);
          });
        }
        let obj = {
          label: arr.divName,
          option: forOption,
        };
        this.optionsPosition.push(obj);
      });
      if (this.formData.division.length == 0) {
        this.formData.position = [];
      }
      this.selectAllPosition();
    },
    selectAllDiv(val) {
      let countDiv = [];
      if (typeof val != "undefined") {
        if (val[val.length - 1] == 999) {
          // เลือกทั้งหมด
          this.formData.division = [];
          let groupDiv = [];
          this.optionsDivision.forEach((f) => {
            f.option.forEach((option) => {
              if (option != 999) {
                groupDiv.push(option.id);
              }
            });
          });
          this.formData.division = groupDiv;
          this.constSelect2 = JSON.parse(
            JSON.stringify(this.formData.division)
          );
        } else {
          this.optionsDivision.forEach((row) => {
            row.option.forEach((option) => {
              countDiv.push(option);
            });
          });
          if (val[0] === 999 && val.length < countDiv.length) {
            // มีเลือกทั้งหมดอยู่แล้ว Tik ลูกออก และเลือกทั้งหมดจะถูกเอาออก
            this.formData.division.splice(0, 1);
            this.constSelect2 = JSON.parse(
              JSON.stringify(this.formData.division)
            );
          } else if (this.constSelect2[0] === 999 && val[0] !== 999) {
            // เอาออกทั้งหมด
            this.formData.division = [];
            this.constSelect2 = JSON.parse(
              JSON.stringify(this.formData.division)
            );
          } else {
            // Tik ลูกทั้งหมดแล้วให้ เลือกทั้งหมด
            let fn = this.constSelect2.findIndex((a) => a === 999);
            if (val.length == countDiv.length - 1 && fn === -1) {
              this.formData.division = [];
              this.optionsDivision.forEach((f) => {
                f.option.forEach((option) => {
                  this.formData.division.push(option.id);
                });
              });
              this.constSelect2 = JSON.parse(
                JSON.stringify(this.formData.division)
              );
            }
          }
        }
      }
    },
    selectAllPosition(val) {
      let countDiv = [];
      if (this.formData.position.length > 0) {
        if (typeof val != "undefined") {
          if (val.length == 1) {
            if (val[0] === 999 && val.length < this.constSelect3.length) {
              // มีเลือกทั้งหมดอยู่แล้ว Tik ลูกออก และเลือกทั้งหมดจะถูกเอาออก
              this.formData.position.splice(0, 1);
              this.constSelect3 = JSON.parse(
                JSON.stringify(this.formData.position)
              );
            }
          }
          if (val[val.length - 1] == 999) {
            // เลือกทั้งหมด
            this.formData.position = [];
            this.optionsPosition.forEach((f) => {
              f.option.forEach((option) => {
                if (option != 999) {
                  this.formData.position.push(option.id);
                }
              });
            });
            this.constSelect3 = JSON.parse(
              JSON.stringify(this.formData.position)
            );
          } else {
            this.optionsPosition.forEach((row) => {
              row.option.forEach((option) => {
                countDiv.push(option);
              });
            });
            if (val[0] === 999 && val.length < countDiv.length) {
              // มีเลือกทั้งหมดอยู่แล้ว Tik ลูกออก และเลือกทั้งหมดจะถูกเอาออก
              this.formData.position.splice(0, 1);
              this.constSelect3 = JSON.parse(
                JSON.stringify(this.formData.position)
              );
            } else if (this.constSelect3[0] === 999 && val[0] !== 999) {
              // เอาออกทั้งหมด
              this.formData.position = [];
              this.constSelect3 = JSON.parse(
                JSON.stringify(this.formData.position)
              );
            } else {
              // Tik ลูกทั้งหมดแล้วให้ เลือกทั้งหมด
              let fn = this.constSelect3.findIndex((a) => a === 999);
              if (val.length == countDiv.length - 1 && fn === -1) {
                this.formData.position = [];
                this.optionsPosition.forEach((f) => {
                  f.option.forEach((option) => {
                    this.formData.position.push(option.id);
                  });
                });
                this.constSelect3 = JSON.parse(
                  JSON.stringify(this.formData.position)
                );
              }
            }
          }
        } else {
          let findPosition999 = this.formData.position.find((e) => e == 999);
          if (typeof findPosition999 != "undefined") {
            let groupDiv = [];
            this.optionsPosition.forEach((f) => {
              f.option.forEach((option) => {
                if (option != 999) {
                  groupDiv.push(option.id);
                }
              });
            });
            this.formData.position = groupDiv;
          } else {
            if (this.formData.position.length > 0) {
              let groupDiv = [];

              this.formData.division.forEach((row) => {
                this.formData.position.forEach((pos) => {
                  this.optionsPosition.forEach((f) => {
                    f.option.forEach((option) => {
                      if (
                        option.divId == row &&
                        option.id != 999 &&
                        option.id == pos
                      ) {
                        groupDiv.push(option.id);
                      }
                    });
                  });
                });
              });
              this.formData.position = groupDiv;
            }
          }
        }
      }
    },
    getRole() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`role`)
        .then((res) => {
          if (res.data.success) {
            this.optionsRole = res.data.obj;
          }
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else {
            this.alertCatchError(e);
          }
        });
    },
    openDialogView() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`role/details`)
        .then((res) => {
          if (res.data.success) {
            this.roleDetail = res.data.obj;
            this.roleDetail.forEach((e) => {
              e.permission.forEach((y) => {
                let view = 0;
                let edit = 0;
                let hide = 0;
                if (y.status == 1) {
                  view = 1;
                } else if (y.status == 2) {
                  view = 1;
                  edit = 1;
                } else if (y.status == 3) {
                  hide = 1;
                }
                y.value = {
                  view: view,
                  edit: edit,
                  hide: hide,
                };
              });
            });
            this.dataManageAll.push(this.roleDetail[this.tabRole - 1]);
            this.dialogView = true;
          }
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else {
            this.alertCatchError(e);
          }
        });
    },
    checkTabRole() {
      this.dataManageAll = [];
      this.dataManageAll.push(this.roleDetail[this.tabRole - 1]);
    },
    checkDisabled() {
      if (
        this.formData.department.length > 0 &&
        this.formData.division.length > 0 &&
        this.formData.role != ""
      ) {
        return false;
      } else {
        return true;
      }
    },
    saveAddAdmin() {
      this.formData.position = this.formData.position;
      this.$emit("saveData", this.formData);
    },
  },
};
</script>